import React from "react"
import Layout from "../chunks/layout"
import { Helmet } from "react-helmet"
import Menu from "../components/menu"
import HeaderImage from "../../static/img/header-image.png"
import Faqs from "../components/faqs"

export default function Contact({ location }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact | Boostik</title>
        <link rel="canonical" href="https://boostik.io/" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className={`background-gradient-home`} id="contact-page">
        <Menu />
        <div className="flex justify-center items-center p-20 contact-container">
          <div className="header-section-content-contact">
            <div className="header-section-title">Contact</div>
            <p className="header-section-description">
              Email us with any questions{" "}
              <a
                href="mailto:info@boostik.io"
                className="text-white text-bold"
              >
                info@boostik.io
              </a>
            </p>
            <p className="header-section-description">
              We would be happy to answer your question and set up a meeting
              with you.
            </p>
          </div>
          <div>
            <img
              style={{ width: "500px" }}
              src={HeaderImage}
              className="header-section-image"
              alt="mobile"
            />
          </div>
        </div>
        <Faqs
          listOfFaqs={[
            {
              title: "WHEN WILL MY ORDER START?",
              description:
                "The start time for all services can vary and is listed on the Service Description. Some services have an instant start time and others may have a start time that is delayed up to 24 hours. Please be sure you review the Service Description for the service you are interested in before placing the order. Please note that for YouTube services, YouTube doesn’t update all stats in real-time so it can take up to 6 hours for you to see the effects from your order.",
            },
            {
              title: "IS THIS SAFE FOR MY ACCOUNT?",
              description:
                "Yes, it’s 100% safe to use Boostik. All of our social media marketing services comply fully with the terms, conditions and guidelines of whichever platform we’re dealing with. There is absolutely no chance whatsoever that your account will be penalized in any way. We constantly monitor all of the major social media players for updates and adapt our services accordingly. This means that you will always be one step ahead and your account will never be in jeopardy.",
            },
            {
              title: "HOW FAST WILL I RECEIVE THE SERVICES?",
              description:
                "Services for TikTok are so fast. We can confidently assure you that in less than half an hour you will have started to see your stats increase.The longest you will ever have to wait for any service will be 24 hours. Please note that delivery times are estimated and may vary during busier periods. Within moments of your purchase, you’ll start seeing people liking your post so naturally that the TikTok algorithm will assume it's trendy and promote your account to the ForYou section. You can select the time it takes to get all likes you ordered, from arriving instantly to taking up to a few hour In case you need more information about our delivery times, just send us an email, and we’ll be glad to resolve your doubts.",
            },
            {
              title: "DO YOU NEED MY PASSWORD?",
              description:
                "It is not necessary to have your password in order to deliver a high-quality service. All we need is a link to the content you want to promote to get started. We do NOT require and will never ask for your password or account access. All we need is your Social Media URL/username and your email so that we can contact you regarding your order process. By doing so, we eliminate the possibility of your Social Media profile being breached by any third parties.",
            },
            {
              title: "WHICH PACKAGE IS RIGHT FOR ME?",
              description:
                "We constantly analyse TikTok, thanks to this we can offer you the 'Best Value' option at an ultra-competitive price. As for the right package for you, this will depend on your requirements. Remember that we are at your disposal in case you need further help, send us an email and we will assist you!",
            },
            {
              title: "WHAT PAYMENT METHODS DO YOU ACCEPT?",
              description:
                "At the moment we accept payments through credit and debit cards.",
            },
            {
              title: "CAN I GET A REFUND?",
              description:
                "Your order is eligible for refund if it has not been completed yet. If we fail to deliver your order, you can request for a full refund. Please note different packages have different delivery periods. ",
            },
            {
              title: "CAN I CANCEL MY ORDER?",
              description:
                "Once an order has been placed it is not always possible to cancel it. Many of our services have very fast delivery speeds, therefore making it impossible to cancel the order before it’s delivered. In the event that you need to cancel an order please contact our Customer Support Service and we will do our best to stop it as soon as possible. We do not issue refunds for services that have been delivered.",
            },
            {
              title: "MORE QUESTIONS?",
              description:
                "If you cannot find your answer here, you can send us an email. Our Customer Support Team will be happy to help you!",
            },
          ]}
          viewName="tiktok"
        />
        <div style={{width: '100%', background: 'white', height: '30px'}}></div>
      </div>
    </Layout>
  )
}
